<template>
  <a-card :bordered="false">
    <a-form-model :label-col="{ span: 4, style: { textAlign: 'left' } }" :model="form" :wrapper-col="{ span: 18 }">
      <a-row>
        <a-col :md="8" :sm="24">
          <a-form-model-item label="昵称" prop="nickname">
            <a-input v-model="form.nickname" :allow-clear="true" :max-length="128"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-model-item label="手机" prop="phone_number">
            <a-input v-model="form.phone_number" :allow-clear="true" :max-length="11"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="() => { this.pagination.current = 1; this.fetch() }">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="(p, f, s) => { this.pagination.current = 1; this.sorter = s; this.fetch() }"
    >
      <template slot="role" slot-scope="role">
        <a-tag color="blue">{{ role === 'admin' ? '管理' : '用户' }}</a-tag>
      </template>
      <template slot="active" slot-scope="active">
        <a-tag :color="active ? 'green' : ''">{{ active ? '激活' : '注销' }}</a-tag>
      </template>
    </a-table>

    <a-pagination
      v-if="pagination.total > 0"
      v-model="pagination.current"
      :pageSize="pagination.pageSize"
      :show-size-changer="true"
      :show-total="total => `共 ${total} 条`"
      :total="pagination.total"
      @change="page => { this.pagination.page = page; this.fetch() }"
      @showSizeChange="(current) => { this.pagination.current = current; this.fetch() }"
      style="margin-top: 24px; float: right;"
    ></a-pagination>
  </a-card>
</template>

<script>
import { getUsers } from '@/api/user'

export default {
  name: 'UserList',
  data () {
    return {
      form: {},
      columns: [
        { dataIndex: 'nickname', title: '昵称', width: 240 },
        { dataIndex: 'role', title: '角色', width: 240, scopedSlots: { customRender: 'role' } },
        { dataIndex: 'phone_number', title: '手机', width: 240 },
        { dataIndex: 'updated_at', title: '更新时间', width: 200, sorter: true },
        { dataIndex: 'active', title: '状态', width: 240, fixed: 'right', scopedSlots: { customRender: 'active' } }
      ],
      dataSource: [],
      loading: false,
      pagination: { current: 1, pageSize: 20, total: 0 },
      sorter: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      const params = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }
      if (this.sorter.field && this.sorter.order) {
        params.sort_by = this.sorter.field
        params.order_by = this.sorter.order
      }
      if (this.form.nickname) params.nickname = this.form.nickname
      if (this.form.phone_number) params.phone_number = this.form.phone_number
      getUsers(params).then(res => {
        this.dataSource = res.data
        this.pagination.total = res.total
      }).finally(() => { this.loading = false })
    }
  }
}
</script>
